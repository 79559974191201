export interface ParsedAddress {
  city?: string
  unit?: string
  state: string
  street: string
  country: string
  postcode: string
  houseNumber: string
}

export interface Address {
  fullAddress: string
  parsedAddress: ParsedAddress
}

export interface Person {
  gender?: 'M' | 'F' | null
  idNumber?: string | null
  lastName: string
  addresses: Address[]
  firstName: string
  citizenship?: string
  dateOfBirth: string // (YYYY-MM-DD) Date of birth
  nationality?: string
  yearOfBirth: string // (YYYY) Year of birth
  placeOfBirth: string
  pepSanctionMatch?: string
}

export interface Document {
  type: 'PASSPORT' | 'ID_CARD' | 'DRIVERS_LICENSE' | 'RESIDENCE_PERMIT'
  number: string
  country: string
  validFrom?: string
  validUntil: string
  placeOfIssue: string
  firstIssue: string
  issueNumber: string
  issuedBy: string
}

export interface DriversLicenseCategory {
  B: boolean
}

export interface DriversLicenseCategoryFrom {
  B: string
}

export interface DriversLicenseCategoryUntil {
  B: string
}

export interface AdditionalVerifiedData {
  driversLicenseCategory: DriversLicenseCategory
  driversLicenseCategoryFrom: DriversLicenseCategoryFrom
  driversLicenseCategoryUntil: DriversLicenseCategoryUntil
}

export interface RiskLabel {
  label: string
  category: string
  sessionIds: string[]
}

export interface Verification {
  id: string
  code:
    | VerificationCodes.VERIFIED
    | VerificationCodes.NOT_VERIFIED
    | VerificationCodes.NEEDS_TO_RESUBMIT
    | VerificationCodes.EXPIRED
    | VerificationCodes.MANUAL
  person: Person
  reason?: string
  status: string
  comments: string[]
  document: Document
  reasonCode?: string
  // Set in ClientModal, string metadata used to link original request to incoming webhook.
  vendorData: string
  decisionTime: Date
  acceptanceTime: Date
  additionalVerifiedData: AdditionalVerifiedData
  riskLabels: RiskLabel[]
}

export interface TechnicalData {
  ip: string
}

export interface DecisionNotification {
  status: string
  verification: Verification
  technicalData: TechnicalData
}

export interface EventNotification {
  id: string
  attemptId: string
  feature: string
  code: number
  action: string
  vendorData: string
}

export enum VerificationCodes {
  STARTED = 7001,
  SUBMITTED = 7002,

  VERIFIED = 9001,
  NOT_VERIFIED = 9102,
  NEEDS_TO_RESUBMIT = 9103,
  EXPIRED = 9104,
  MANUAL = 9121,
}

export enum IdentifiedStatus {
  INIT = 'INIT',
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
}
