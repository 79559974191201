import { Box, Grid, Typography, Link as MuiLink, useTheme } from '@mui/joy'
import Image from 'next/image'

export default function Footer() {
  const theme = useTheme()

  return (
    <>
      <MuiLink
        href="https://www.basta.ai"
        target="_BLANK"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          margin: '64px auto',
        }}
        width={140}
      >
        <Typography
          level="body1"
          textAlign="center"
          sx={{ marginRight: 1, textDecoration: 'none', color: '#9AA2AB' }}
        >
          Powered by
        </Typography>
        <svg
          width="45"
          height="12"
          viewBox="0 0 45 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_254_249)">
            <path
              d="M6.30118 4.99299C6.30118 4.99299 6.29765 4.99155 6.29765 4.98963C6.29765 4.98772 6.29916 4.98628 6.30118 4.98628C7.50361 4.90775 8.33029 3.91318 8.33029 2.74382V2.23145C8.33029 0.995061 7.42493 0.000488281 6.13928 0.000488281H0.00403502C0.00403502 0.000488281 0 0.00240368 0 0.00431909V11.9947C0 11.9947 0.00201751 11.9986 0.00403502 11.9986H3.62798C3.62798 11.9986 3.63202 11.9967 3.63202 11.9947V2.39139C3.62395 2.18165 3.79695 2.00592 4.01736 1.99825C4.23828 1.99059 4.42339 2.15484 4.43146 2.3641C4.43146 2.37319 4.43146 2.38229 4.43146 2.39091V11.9943C4.43146 11.9943 4.43347 11.9981 4.43549 11.9981H6.13474C7.433 11.9943 8.48463 10.9959 8.48816 9.7633V7.22012C8.47908 6.05125 7.52883 5.08349 6.30118 4.99299Z"
              fill="#9AA2AB"
            />
            <path
              d="M15.2287 0H11.8705C10.5723 0.00383081 9.52064 1.00223 9.5166 2.2348V11.9943C9.5166 11.9943 9.51761 11.9976 9.51963 11.9981C9.51963 11.9981 9.52013 11.9981 9.52064 11.9981H13.1451C13.1451 11.9981 13.1486 11.9971 13.1491 11.9952C13.1491 11.9952 13.1491 11.9947 13.1491 11.9943V4.71572H13.9491V11.9943C13.9491 11.9943 13.9511 11.9981 13.9531 11.9981H17.5776C17.5776 11.9981 17.5811 11.9971 17.5816 11.9952C17.5816 11.9952 17.5816 11.9947 17.5816 11.9943V2.2348C17.5821 1.0008 16.5284 0 15.2287 0ZM13.1496 3.82075V2.41101C13.1456 2.20942 13.303 2.03751 13.5138 2.01357C13.7337 1.99489 13.9284 2.14908 13.9481 2.35786C13.9491 2.36887 13.9496 2.38037 13.9496 2.39138V3.82075H13.1496Z"
              fill="#9AA2AB"
            />
            <path
              d="M24.3629 0H21.0053C19.707 0.00383081 18.6554 1.00223 18.6514 2.2348V5.52546C18.6514 5.52546 18.6534 5.52929 18.6554 5.52929H22.6844C22.7792 5.52929 22.871 5.56185 22.9431 5.61979L22.9698 5.64373C23.043 5.7146 23.0838 5.8099 23.0838 5.90902V9.27582C23.0884 9.47741 22.931 9.64932 22.7202 9.67374C22.5003 9.69242 22.3056 9.53823 22.2859 9.32945C22.2849 9.31796 22.2844 9.30694 22.2844 9.29545V6.29258C22.2844 6.29258 22.2824 6.28875 22.2804 6.28875H18.6554C18.6554 6.28875 18.6514 6.29066 18.6514 6.29258V9.76329C18.6554 10.9958 19.707 11.9943 21.0053 11.9981H24.3629C25.6627 11.9981 26.7164 10.9982 26.7169 9.76425V4.9269C26.7169 4.9269 26.7148 4.92306 26.7128 4.92306H22.6844C22.5926 4.92306 22.5033 4.8929 22.4322 4.83783C22.4201 4.82873 22.409 4.81915 22.3984 4.80862C22.3252 4.73775 22.2844 4.64246 22.2844 4.54334V2.63033C22.2799 2.42873 22.4377 2.25634 22.6486 2.2324C22.8685 2.21373 23.0626 2.36744 23.0823 2.57622C23.0833 2.58771 23.0838 2.59872 23.0838 2.61022V4.15786C23.0838 4.15786 23.0858 4.16169 23.0879 4.16169H26.7128C26.7128 4.16169 26.7169 4.15978 26.7169 4.15786V2.2348C26.7169 1.00032 25.6632 0 24.3629 0Z"
              fill="#9AA2AB"
            />
            <path
              d="M31.5113 2.33679C31.5113 2.33679 31.5072 2.33488 31.5072 2.33296V0.00383081C31.5072 0.00383081 31.5052 0 31.5032 0H27.7642C27.7642 0 27.7602 3.25188 27.7602 1.72386V2.3593H27.165V3.20543H27.7602V8.05762C27.7602 10.9901 28.8557 12 30.1101 12H33.5837C34.884 12 35.9377 10.9997 35.9377 9.7652V3.82745C35.9377 3.82745 35.9356 3.82362 35.9336 3.82362H32.1947C32.1947 3.82362 32.1912 3.82458 32.1906 3.8265C32.1906 3.8265 32.1906 3.82698 32.1906 3.82745V9.18962C32.1906 9.59521 31.5087 9.56983 31.5087 9.1585V3.21165C31.5087 3.21165 31.5108 3.20782 31.5128 3.20782H35.9513C35.9513 3.20782 35.9553 3.20591 35.9553 3.20399V2.34206C35.9553 2.34206 35.9533 2.33823 35.9513 2.33823L31.5113 2.33679Z"
              fill="#9AA2AB"
            />
            <path
              d="M42.6462 0H39.2875C37.9893 0.00383081 36.9376 1.00223 36.9336 2.2348V11.9943C36.9336 11.9943 36.9356 11.9981 36.9376 11.9981H40.5636C40.5636 11.9981 40.5676 11.9962 40.5676 11.9943V4.71572H41.3676V11.9943C41.3676 11.9943 41.3686 11.9976 41.3706 11.9981C41.3706 11.9981 41.3711 11.9981 41.3716 11.9981H44.9961C44.9961 11.9981 44.9996 11.9971 45.0001 11.9952C45.0001 11.9952 45.0001 11.9947 45.0001 11.9943V2.2348C45.0006 1.0008 43.947 0 42.6472 0H42.6462ZM40.5676 3.82075V2.41101C40.5631 2.20942 40.721 2.03751 40.9318 2.01357C41.1517 1.99489 41.3464 2.14908 41.3661 2.35786C41.3671 2.36887 41.3676 2.38037 41.3676 2.39138V3.82075H40.5676Z"
              fill="#9AA2AB"
            />
          </g>
          <defs>
            <clipPath id="clip0_254_249">
              <rect width="45" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </MuiLink>

      <Grid
        container
        sx={{
          width: '100%',
          padding: ' 32px 16px',
          placeItems: 'center',
          backgroundColor: '#3D3D3A',
          [theme.breakpoints.up('lg')]: {
            padding: '40px 40px 40px 0px',
          },
        }}
      >
        <Grid lg={2} xs={12}>
          <Box textAlign="center">
            <Image
              src="/assets/logo-white.png"
              alt="presentation"
              width={134}
              height={134}
            />
          </Box>
        </Grid>
        <Grid lg={2} xs={12}>
          <Box
            sx={{
              textAlign: 'center',
              [theme.breakpoints.up('lg')]: {
                textAlign: 'start',
              },
            }}
          >
            <MuiLink
              href="mailto:INQUIRE@HEDGEROWEXCLUSIVE.COM"
              level="body5"
              sx={{ color: 'white', textDecoration: 'none' }}
            >
              INQUIRE@HEDGEROWEXCLUSIVE.COM
            </MuiLink>
            <Typography level="body5" sx={{ color: 'white' }}>
              +1 631.296.8543
            </Typography>
            <Box p={1} />
            <Typography level="body5" sx={{ color: 'white' }}>
              2495 MONTAUK HWY UNIT A
            </Typography>
            <Typography level="body5" sx={{ color: 'white' }}>
              BRIDGEHAMPTON, NY 11932
            </Typography>
            <Box p={1} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                justifyContent: 'center',
                textAlign: 'center',
                [theme.breakpoints.up('lg')]: {
                  justifyContent: 'start',
                },
              }}
            >
              <MuiLink
                href="https://www.facebook.com/Hedgerow.Exclusive/"
                target="_BLANK"
              >
                <Image
                  alt="facebook-footer-logo"
                  src="/assets/svg/Facebook-Original.svg"
                  width={24}
                  height={24}
                  style={{ marginBottom: '4px' }}
                />
              </MuiLink>

              <MuiLink
                href="https://www.instagram.com/hedgerow.exclusive/"
                target="_BLANK"
              >
                <Image
                  alt="instagram-footer-logo"
                  src="/assets/svg/Instagram-Black.svg"
                  width={24}
                  height={24}
                />
              </MuiLink>
            </Box>
          </Box>
        </Grid>
        <Grid lg={5} xs={0} />
        <Grid lg={3} xs={12}>
          <Box sx={{ whiteSpace: 'nowrap' }}>
            <Box p={5} />
            <Box
              sx={{
                display: 'flex',
                gap: '32px',
                justifyContent: 'center',
                textAlign: 'center',

                [theme.breakpoints.up('lg')]: {
                  justifyContent: 'flex-end',
                },
              }}
            >
              <MuiLink
                level="body5"
                sx={{ color: 'white', textDecoration: 'none' }}
                href="https://hedgerowexclusive.com/legal/"
                target="_BLANK"
              >
                LEGAL
              </MuiLink>
              <MuiLink
                level="body5"
                sx={{ color: 'white', textDecoration: 'none' }}
                href="https://hedgerowexclusive.com/privacy-policy/"
                target="_BLANK"
              >
                PRIVACY POLICY
              </MuiLink>
              <MuiLink
                level="body5"
                sx={{ color: 'white', textDecoration: 'none' }}
                href="https://hedgerowexclusive.com/terms/"
                target="_BLANK"
              >
                TERMS
              </MuiLink>
              <MuiLink
                level="body5"
                sx={{ color: 'white', textDecoration: 'none' }}
                href="/assets/Hedgerow_Offer_Terms.pdf"
                target="_BLANK"
                underline="none"
              >
                OFFER TERMS
              </MuiLink>
            </Box>
            <Box p={1} />
            <Typography
              level="body5"
              sx={{
                color: 'white',
                textTransform: 'uppercase',
                flexWrap: 'nowrap',
                textAlign: 'center',
                [theme.breakpoints.up('lg')]: {
                  textAlign: 'end',
                },
              }}
            >
              © 2023, Hedgerow Exclusive Properties
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
