import React from 'react'
import Link from 'next/link'
import { signIn, signOut, useSession } from 'next-auth/react'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  Box,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from '@mui/joy'
import Image from 'next/image'
import { IdentifiedStatus } from '../../types/veriff'

export default function Header() {
  const { data: session } = useSession()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.vars.palette.primary['500'],
        borderRadius: 0,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={0} sm={4}>
            <br />
          </Grid>

          <Grid
            xs={6}
            sm={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
              },
            }}
          >
            <Link
              href={'https://hedgerowexclusive.com/'}
              style={{
                position: 'relative',
              }}
            >
              <Image
                src="/assets/hedgerow-logo-horizontal.png"
                alt="Hedgerow logo"
                width={104}
                height={10}
                style={{
                  objectFit: 'contain',
                }}
              />
            </Link>
          </Grid>

          <Grid
            xs={6}
            sm={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {session ? (
              <>
                <Button size="sm" onClick={handleClick} sx={{ height: 46 }}>
                  <Box
                    sx={{
                      maxWidth: 152,
                      [theme.breakpoints.up('md')]: {
                        maxWidth: 304,
                      },
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {session.user?.name ?? session.user?.email ?? 'N/A'}
                  </Box>

                  {session.user?.isIdentified === IdentifiedStatus.VERIFIED && (
                    <Tooltip title="You are identified">
                      <CheckCircleIcon
                        width={16}
                        style={{ marginLeft: 4, marginRight: 4 }}
                      />
                    </Tooltip>
                  )}
                  <ChevronDownIcon width={16} />
                </Button>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  {session.user?.isAdmin &&
                    !window.location.pathname.includes('/admin') && (
                      <MenuItem component={Link} href={'/admin'}>
                        Admin Portal
                      </MenuItem>
                    )}
                  {session.user?.isAdmin &&
                    window.location.pathname.includes('/admin') && (
                      <MenuItem component={Link} href={'/'}>
                        Rental Page
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={() =>
                      signOut({
                        callbackUrl: '/',
                      })
                    }
                  >
                    Sign Out
                    <Box sx={{ width: 8 }} />
                    <ArrowLeftOnRectangleIcon width={20} />
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button size="sm" onClick={() => signIn()} sx={{ height: 46 }}>
                Sign In
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
